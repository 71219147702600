import consumer from './consumer'
import RealtimeActions from '../../client/redux/actions/realtime_actions'

if (window.appType === 'Roots' || window.appType === 'Mobile') {
  consumer.subscriptions.create('StockPlanningsChannel', {
    received (data) {
      RealtimeActions.dispatch(data.event, Model.StockPlanning, data.data)
    }
  })
}
