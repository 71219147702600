require('client/redux/actions/phone_call')

class RealtimeActions {
  static initialize () {
    let models

    if (window.appType === 'Mobile') {
      models = [
        Model.Stock,
        Model.StockPlanning,
        Model.Transport,
        Model.Order
      ]
    }

    if (window.appType === 'Roots') {
      models = [
        Model.Stock,
        Model.StockPlanning,
        Model.Transport,
        Model.PhoneCall,
        Model.CallbackRequest,
        Model.Order,
        Model.Employee
      ]
    }

    models.forEach(model => {
      this.createModelReducer(model)
    })
  }

  static createModelReducer (model) {
    model.createReducer((_model, collection, action) => {
      if (model !== action?.payload?.model) {
        return collection
      }

      if (action.type === `REALTIME_DELETED_${model.singularName.toUpperCase()}`) {
        return collection.delete(action.payload.id)
      }

      return collection
    })
  }

  static normalizedData (model, data) {
    return model._normalizeResponse({ data: { [model.singularName]: data } })
  }

  static dispatch (event, model, data) {
    let payload

    if (event !== 'deleted') {
      payload = {
        model,
        normalizedResponse: this.normalizedData(model, data)
      }
    } else {
      payload = {
        model,
        id: data.id
      }
    }

    const type = `REALTIME_${event}_${model.singularName}`.toUpperCase()

    store.dispatch({
      type,
      payload
    })
  }
}

module.exports = RealtimeActions
