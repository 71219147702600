const prefixes = ['camera-s', 'dslr-action', 'lenzen', 'grip', 'geluid', 'licht', 'overig', 'accessoires']

const sortOrder = (product) => {
  const stockPrefix = (product.stock_number_prefix || '').substr(-1)
  const prefixIndex = prefixes.indexOf(product.prefix)
  return [
    prefixIndex === -1 ? prefixes.length : prefixIndex,
    stockPrefix,
    product.name || ''
  ]
}

const sortCollection = ({ collection, products, stocks }) => {
  if (stocks.size() === 0 || products.size() === 0) {
    return collection
  }

  return collection.sortBy((record) => {
    const stock = stocks.getById(record.stock_id)

    if (stock) {
      const product = products.getById(stock.product_id)

      if (product) {
        return [...sortOrder(product), stock.bag_number, stock.number]
      }
    }
    return prefixes.length
  })
}

const sortPlannings = ({ plannings, products, stocks }) => {
  return sortCollection({ collection: plannings, products, stocks })
}

const sortTransports = ({ transports, products, stocks }) => {
  return sortCollection({ collection: transports, products, stocks })
}

export { sortTransports, sortPlannings }
