const I18nJS = require('i18n-js').I18n

const i18nSetup = () => {
  const locale = document.documentElement.getAttribute('lang')
  const translations = JSON.parse(document.documentElement.getAttribute('data-translations'))

  window.I18n = new I18nJS(translations)
  window.I18n.defaultLocale = 'en'
  window.I18n.locale = locale
}

module.exports = i18nSetup;
